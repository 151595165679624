<template>
  <v-container style="max-width: 1285px">
    <v-row class="text-center">
      <v-col cols="12" style="padding: 0">
        <v-layout
          v-if="loadedPercentage < 100"
          align-center
          justify-center
          column
          fill-height
        >
          <v-flex row align-center>
            <v-progress-circular
              indeterminate
              :size="50"
              color="primary"
              class=""
            ></v-progress-circular>
          </v-flex>
        </v-layout>
        <div class="canvas-container">
          <canvas
            id="canvas"
            height="720"
            width="1280"
            style="width: 100%; height: 100%"
            :class="{ grabbing: holding, grab: !holding }"
            @mousedown="down"
            @mouseup="up"
            @mousemove="move"
          />
          <template v-if="selectedImg !== null && selectedImg > -1">
            <div
              class="text-center"
              v-for="pin in getPins"
              :key="pin.position.x + '_' + pin.position.y"
            >
              <div
                :class="
                  pin.type === 'Hotspot' || pin.type === 'Feature'
                    ? 'greendot'
                    : 'reddot'
                "
              >
                <v-tooltip top :disabled="$vuetify.breakpoint.mobile">
                  <template v-slot:activator="{ on, attrs }">
                    <font
                      @click="
                        selectedPin = pin;
                        $emit('openImage', pin.image);
                      "
                      :id="pin.id"
                      v-bind="attrs"
                      v-on="on"
                      :style="calculate(pin)"
                    ></font
                    ><!--the actual dot-->
                  </template>

                  <v-card>
                    <v-img
                      v-if="pin.image"
                      :src="
                        getImageURL(spin, 'exterior', 'thumbnails', pin.image)
                      "
                      class="grey darken-4"
                    ></v-img>
                    <v-card-title class="subtitle-1" style="max-width: 200px">
                      {{ pin.name }}
                    </v-card-title>
                    <v-card-subtitle style="width: 200px">
                      {{ pin.description }}
                    </v-card-subtitle>
                  </v-card>
                </v-tooltip>
              </div>
            </div>
          </template>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import canvasMixin from "@/mixins/canvas";
import imageURLs from "@/mixins/imageURLs";
import { sensitivity } from "@/utils/consts";

export default {
  name: "SpinPlayer",
  mixins: [canvasMixin, imageURLs],
  data: () => ({
    sensitivity,
  }),
  mounted() {
    this.canvas.addEventListener(
      "touchstart",
      (e) => {
        this.lastLocation = this.getTouchPos(this.canvas, e).x;
        var touch = e.touches[0];
        this.down(touch.clientX);
      },
      false
    );
    this.canvas.addEventListener(
      "touchend",
      () => {
        console.log("end");
        this.up();
      },
      false
    );
    this.canvas.addEventListener(
      "touchmove",
      (e) => {
        console.log("move");
        var touch = e.touches[0];
        this.move(touch.clientX);
      },
      false
    );
  },
  computed: {
    loadedPercentage() {
      if (this.spin.images360 && this.countLoadedImages) {
        console.log(
          (this.countLoadedImages * 100) / this.spin.images360.length
        );
        return (this.countLoadedImages * 100) / this.spin.images360.length;
      }
      return 0;
    },
  },
  watch: {
    countLoadedImages(val) {
      if (this.spin.images360) {
        if (val === this.spin.images360.length) this.drawImage();
      }
    },
  },
  methods: {
    getTouchPos(canvasDom, touchEvent) {
      var rect = canvasDom.getBoundingClientRect();
      return {
        x: touchEvent.touches[0].clientX - rect.left,
        y: touchEvent.touches[0].clientY - rect.top,
      };
    },
    down(e) {
      this.holding = !this.holding;
      this.lastLocation = e.screenX ? e.screenX : e;
    },
    up() {
      this.holding = false;
    },
    move(e) {
      const x = e.screenX ? e.screenX : e;
      if (this.holding) {
        if (x > this.lastLocation) {
          if (x - this.sensitivity > this.lastLocation) {
            if (this.selectedImg === 0) {
              this.selectedImg = this.spin.images360.length - 1;
            } else {
              this.selectedImg--;
            }
            this.lastLocation = x;
            this.drawImage();
          }
        } else {
          if (x + this.sensitivity < this.lastLocation) {
            if (this.selectedImg === this.spin.images360.length - 1) {
              this.selectedImg = 0;
            } else {
              this.selectedImg++;
            }
            this.lastLocation = x;
            this.drawImage();
          }
        }
      }
    },
  },
};
</script>
<style>
.canvas-container {
  position: relative;
}
.grab {
  cursor: grab;
}
.grabbing {
  cursor: grabbing;
}
</style>
