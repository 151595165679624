<template>
  <preview />
</template>
<script>
import Preview from "@/components/player/Main";
//import Panorama from "@/components/spins/Panorama";
export default {
  components: {
    Preview,
    //Panorama,
  },
};
</script>