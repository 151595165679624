<template>
  <v-row class="text-center" justify="center" align="center">
    <v-col cols="12" style="padding: 0">
      <v-container style="max-width: 1285px">
        <v-card>
          <v-tabs v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>

            <v-tab v-for="(t, i) in tabs" :key="t" :href="`#tab-${i + 1}`">
              {{ t }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" touchless>
            <template v-if="tabs && tabs.length === 2">
              <v-tab-item :key="1" :value="'tab-1'">
                <v-row class="text-center">
                  <v-col cols="12">
                    <exterior-player @openImage="openImage" />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item :key="2" :value="'tab-2'">
                <interior-player @openImage="openImage" />
              </v-tab-item>
            </template>
            <v-tab-item v-else :key="1" :value="'tab-1'">
              <v-row class="text-center">
                <v-col cols="12">
                  <exterior-player @openImage="openImage" />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-container>
      <CoolLightBox
        :fullScreen="true"
        :items="images"
        :index="index"
        @close="index = null"
      >
      </CoolLightBox>
      <!-- <CoolLightBox
        class="smaller-img"
        :fullScreen="true"
        :items="images"
        :index="index"
        @close="index = null"
        :style="{ backgroundColor: 'rgba(30, 30, 30, 0.6)' }"
      >
      </CoolLightBox> -->
    </v-col>
  </v-row>
</template>

<script>
import ExteriorPlayer from "./ExteriorPlayer.vue";
import InteriorPlayer from "./InteriorPlayer.vue";
import imageURLs from "@/mixins/imageURLs";

export default {
  components: {
    ExteriorPlayer,
    InteriorPlayer,
  },
  mixins: [imageURLs],
  data: () => ({
    tab: null,
    index: null,
  }),
  computed: {
    spin() {
      return this.$store.state.spin;
    },
    images() {
      if (!this.spin.interiorImages) return [];
      return [
        ...this.spin.interiorImages.map((ei) => {
          const hotspot = this.spin.panoHotspots.find(
            (ph) => ph.img?.length && ph.img[0] === ei
          );
          return {
            title: hotspot?.text,
            description: hotspot?.description,
            src: this.getImageURL(this.spin, "interior", "resized", ei),
          };
        }),
        ...this.spin.exteriorImages.map((ei) => {
          const hotspot = this.spin.hotspots.find(
            (ph) => ph.image?.length && ph.image[0] === ei
          );
          return {
            title: hotspot?.name,
            description: hotspot?.description,
            src: this.getImageURL(this.spin, "exterior", "resized", ei),
          };
        }),
      ];
    },
    tabs() {
      if (this.spin.panoramaImages && this.spin.panoramaImages.length) {
        return [this.$t("360exterior"), this.$t("360interior")];
      }
      return [this.$t("360exterior")];
    },
  },
  methods: {
    openImage(img) {
      if (img && img[0]) {
        const findIndex = this.images.findIndex((i) => i.src.includes(img));
        this.index = findIndex;
      }
    },
  },
};
</script>
<style>
#test {
  position: relative;
}
.grab {
  cursor: grab;
}
.grabbing {
  cursor: grabbing;
}
.cool-lightbox__slide__img > img {
  max-width: 800px !important;
  max-height: 1000px !important;
}
</style>
