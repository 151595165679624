<template>
  <v-container style="max-width: 1285px">
    <v-row class="text-center">
      <v-col cols="12" style="padding: 0">
        <div id="panorama" class="vue-pano-viewer" ref="viewer" :style="size" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* import "pannellum";
import "pannellum/build/pannellum.css"; */
import "pannellum/src/js/libpannellum.js";
import "pannellum/src/js/pannellum.js";
import "pannellum/src/css/pannellum.css";
import imageURLs from "@/mixins/imageURLs";
export default {
  components: {},
  mixins: [imageURLs],
  data: () => ({
    viewer: null,
    panorama: null,
    holding: false,
    canvas: null,
  }),
  computed: {
    panoPins() {
      return this.$store.state.panoPins.map((p) => {
        return {
          ...p,
          cssClass: p.cssClass || 'green-hotspot',
          draggable: false,
          clickHandlerArgs: p.id,
          clickHandlerFunc: () => {
            this.$emit('openImage', p.img);
          },
        };
      });
    },
    size() {
      const { width, height } = this.canvas.getBoundingClientRect();
      return `height: ${height}px; width: ${width}`;
    },
    spin() {
      return this.$store.state.spin;
    },
  },
  created() {
    this.canvas = document.getElementById("canvas");
  },
  mounted() {
    this.viewer = window.pannellum.viewer("panorama", {
      type: "equirectangular",
      panorama: this.getImageURL(
        this.spin,
        "panorama",
        null,
        this.spin.panoramaImages[0]
      ),
      autoLoad: true,
      hotSpots: this.panoPins,
      hfov: 120,
      startOrientation: true,
      orientationOnByDefault: true,
    });
  },
};
</script>
<style scoped>
.vue-pano-viewer {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>